$( document ).ready(function() {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);

	const $inputCode = $('input[name="quiz[pass_code]"]');
	const $inputHouse = $('input[name="quiz[house]"]');

	const $questions = $('input.answer-input[data-question]');
	const $next = $('.quiz__button');
	let codeId = '';

	$inputCode.keyup(function (evt) {
		codeId = $(this).val();
		const value = $(this).val().slice(-1);
		const	st = RegExp('[0-9]');
		if (st.test(value)) {
			(codeId.length > 8 && codeId.length < 20) ? $next.addClass('active') : $next.removeClass('active');
		}
		else {
			$(this).val($(this).val().slice(0,-1));
		}
		if (evt.keyCode === 13) {
			(codeId.length > 8 && codeId.length < 20) ? $next.click() : evt.preventDefault();;
		}
	});

	$next.on('click', function () {
		const $screen = $('.screen.active');
		if ($screen.is('.screen__id ')) {
/*			$.get('/pass_code/' + codeId, function (data) {
				data.available === true ? nextScreen() : errorCode();
			}).fail(function() {
				errorCode()
			});*/
			nextScreen()
			$('.quiz__content').css('justify-content', 'space-between');
			$next.css('margin-top', 0);
		}
		else {
			if ($screen.is('.screen__question-secondlast')) {
				nextScreen()
				$('.quiz__next-button').remove();
				$('.quiz__finish-button').removeAttr("disabled");
			}else {
				nextScreen()
			}
		}

		function nextScreen() {
			const $nextScreen = $screen.removeClass('active').next().addClass('active');
			const nextScreen = $nextScreen[0];
			if (nextScreen.dataset.question === '4') {
				if (Math.random() < 0.25)
					setTimeout(releaseTheSnitch, Math.random() * 3000);
			}
			$next.removeClass(('active'));
		}
/*		function  errorCode() {

		}*/
	});

	chooseAnswer($inputHouse);
	chooseAnswer($questions);

	function chooseAnswer (inputAnswer) {
		inputAnswer.click(function () {
			if (inputAnswer.is(':checked'))
				$next.addClass('active');
		});
	}

	function releaseTheSnitch() {
		const snitch = document.createElement('div');
		const house = document.querySelector('input[name="quiz[house]"]:checked').value;
		snitch.className = 'flying-snitch';
		const startPoint = randomSidePoint();
		let endPoint;
		do {
			endPoint = randomSidePoint();
		} while (endPoint.side === startPoint.side || Math.hypot(endPoint.p.x - startPoint.p.x, endPoint.p.y - startPoint.p.y) < 180);
		snitch.style.left = `${startPoint.p.x}px`;
		snitch.style.top = `${startPoint.p.y}px`;
		snitch.addEventListener('click', () => {
			$.ajax(`/quiz/${concertSlug}/catch_snitch/${house}`)
				.done(({ status }) => {
					snitch.remove();
					if (status === 'catched') {
						$('.snitch-result_catched').addClass('snitch-result_active');
						setTimeout(
							() => { $('.snitch-result_catched').find('.snitch-result__close').addClass('snitch-result__close_active') },
							2000
						);
					} else {
						$('.snitch-result_missed').addClass('snitch-result_active');
						setTimeout(
							() => { $('.snitch-result_missed').find('.snitch-result__close').addClass('snitch-result__close_active') },
							2000
						);
					}
				})
				.fail(() => {
					snitch.remove();
					$('.snitch-result_missed').addClass('snitch-result_active');
					setTimeout(
						() => { $('.snitch-result_missed').find('.snitch-result__close').addClass('snitch-result__close_active') },
						2000
					);
				});
		});
		document.body.append(snitch);
		$(snitch).animate(
			{
				left: endPoint.p.x,
				top: endPoint.p.y
			},
			3000,
			'linear',
			() => snitch.remove());
	}

	window.releaseTheSnitch = releaseTheSnitch;

	function randomSidePoint() {
		const w = window.innerWidth - 40;
		const h = window.innerHeight - 40;
		const length = 2 * w + 2 * h;
		let p = Math.floor(Math.random() * length);
		if (p < w)
			return { side: 0, p: { x: p, y: 0 } };
		p -= w;
		if (p < h)
			return { side: 1, p: { x: w, y: p } };
		p -= h;
		if (p < w)
			return { side: 2, p: { x: w - p, y: h } };
		p -= w;
		return { side: 3, p: { x: 0, y: h - p } };
	}

	$('.snitch-result__close').on('click', function () {
		this.closest('.snitch-result').classList.remove('snitch-result_active');
	});

	initializeResults();
});

function initializeResults() {
	const results = document.querySelector('.results');
	if (!results)
		return;

	const finals = results.dataset.refresh === 'auto';
	const refreshResults = () => updateResults(results, finals);
	if (finals) {
		refreshResults();
		setInterval(refreshResults, 60000);
	}
	results.addEventListener('click', refreshResults);
}

let currentResults = {
	phase: 0,
	house: {1: {score: 0, level: 0}, 2: {score: 0, level: 0}, 3: {score: 0, level: 0}, 4: {score: 0, level: 0}},
	max_level: 0
};

function updateResults(results, isFinals) {
	$.get(results.dataset.url, data => {
		const houses = [1, 2, 3, 4];
		const maxScore = houses.map(h => data.house[h].score).reduce((s, x) => Math.max(s, x));
		const maxDiffScore = houses.map(h => data.house[h].score - currentResults.house[h].score)
									.reduce((s, x) => Math.max(s, x));
		let speed = 100 / 1000; // scores per millisecond
		let limit = maxDiffScore / speed;
		const maxLimit = 20000;
		// const maxLimit = 5000;
		if (limit > maxLimit) {
			speed = maxDiffScore / maxLimit;
			limit = maxLimit;
		}

		const sandSrcSize = 130;
		const sandDstSize = 380;
		const sandIndicatorSize = 355;
		let start;

		if (isFinals) {
			// const oldMaxScore = Math.max.apply(Math, Object.values(currentResults.house).map(h => h.score));
			// const oldMaxScore = Math.max.apply(Math, houses.map(h => currentResults.house[h].score));
			for (let h = 1; h <= 4; h++) {
				// const countScore = $(`#score-house-${h}`);
				const sandSrc = $(`#sand-top-${h}`);
				const sandDst = $(`#sand-bottom-${h}`);
				const sandIndicator = $(`#sand-level-indicator-${h}`);
				const oldScore = currentResults.house[h].score;
				// const newScore = data.house[h].score;
				const levelPart = oldScore / maxScore;
				const sandLevel = sandDstSize * (1 - levelPart);
				const sandLeftLevel = sandSrcSize * levelPart;
				const sandIndicatorLevel = sandIndicatorSize * (1 - levelPart);
				// countScore.text(score);
				sandDst.css('background-position-y', sandLevel);
				sandSrc.css('background-position-y', sandLeftLevel);
				sandIndicator.css('background-position-y', sandIndicatorLevel);
			}
		}

		function step(timestamp) {
			if (start === undefined) {
				start = timestamp;
			}
			const elapsed = timestamp - start;

			for (let h = 1; h <= 4; h++) {
				const countScore = $(`#score-house-${h}`);
				const sandSrc = $(`#sand-top-${h}`);
				const sandDst = $(`#sand-bottom-${h}`);
				const sandIndicator = $(`#sand-level-indicator-${h}`);
				const oldScore = currentResults.house[h].score;
				const newScore = data.house[h].score;
				const score = Math.min(oldScore + Math.round(elapsed * speed),
										newScore);
				const diffScore = newScore - oldScore;
				const currDiffScore = score - oldScore;
				const oldLevel = currentResults.house[h].level;
				const newLevel = data.house[h].level;
				const diffLevel = newLevel - oldLevel;
				const constantNumber = (oldLevel + diffLevel * (currDiffScore / diffScore)) / 100;
				const sandLevel = sandDstSize * (1 - constantNumber);
				const sandLeftLevel = sandSrcSize * constantNumber;
				const sandIndicatorLevel = sandIndicatorSize * (1 - constantNumber);
				countScore.text(score);
				sandDst.css('background-position-y', sandLevel);
				sandSrc.css('background-position-y', sandLeftLevel);
				sandIndicator.css('background-position-y', sandIndicatorLevel);
			}

			if (elapsed < limit) {
				window.requestAnimationFrame(step);
			} else {
				currentResults = data;
				if (currentResults.phase === 4) {
					$('.golden-snitch')
						.removeClass('golden-snitch-1 golden-snitch-2 golden-snitch-3 golden-snitch-4')
						.addClass(`golden-snitch-${currentResults.snitch_house}`);
					const winnerHouse = Object.entries(currentResults.house)
							.sort(([_ha, { score: sa }], [_hb, { score: sb }]) => sb - sa)[0][0];
					$('.winner-cup')
						.removeClass('winner-cup-1 winner-cup-2 winner-cup-3 winner-cup-4')
						.addClass(`winner-cup-${winnerHouse}`);
				}
			}
		}

		window.requestAnimationFrame(step);
	});
}
